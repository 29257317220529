import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Auth } from '@layouts';
import useUser from '@hooks/useUser';
import useABTesting from 'hooks/useABTesting';

export default function ResetPassword({ navigate, location: { hash } }) {
  const { t } = useABTesting();
  const [error, setError] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] =
    useState(false);
  const [errors, setErrors] = useState([]);
  const { resetPassword } = useUser();
  const { isError, isLoading, mutate, isSuccess } = resetPassword;

  useEffect(() => {
    const hashQuery = new URLSearchParams(hash.substring(1));
    setAccessToken(hashQuery.get('access_token'));
    const queryError = hashQuery.get('error_description') || '';
    setError(queryError.replaceAll('+', ' '));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = [];
    if (!password || password.length < 6) {
      errors.push('password');
    }
    if (!passwordConfirmation || passwordConfirmation.length < 6) {
      errors.push('passwordConfirmation');
    }
    if (password !== passwordConfirmation) {
      errors.push('password');
      errors.push('passwordConfirmation');
    }

    if (!accessToken) {
      errors.push('accessToken');
    }

    if (errors.length) {
      setErrors(errors);
    } else {
      setErrors([]);
      mutate({ password, accessToken });
    }
  };

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleTogglePasswordConfirmation = () => {
    setPasswordConfirmationVisible(!passwordConfirmationVisible);
  };

  if (isSuccess) {
    navigate('/sign-in');
  }

  return (
    <Auth component="main" maxWidth="xs">
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          color="primary"
          component="h1"
          align="left"
          variant="h4"
          sx={{ width: '100%', mb: 3 }}
        >
          {t('pages.reset_password.title')}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                placeholder={t('components.input.password.placeholder')}
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
                error={errors.indexOf('password') !== -1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        edge="end"
                      >
                        {passwordVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="passwordConfirmation"
                placeholder={t(
                  'components.input.passwordConfirmation.placeholder',
                )}
                type={passwordConfirmationVisible ? 'text' : 'password'}
                id="passwordConfirmation"
                autoComplete="new-password"
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                error={errors.indexOf('passwordConfirmation') !== -1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password confirmation visibility"
                        onClick={handleTogglePasswordConfirmation}
                        edge="end"
                      >
                        {passwordConfirmationVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
            sx={{ mt: 3, mb: 2 }}
          >
            {t('pages.reset_password.button')}
          </Button>
          {error || isError ? (
            <Typography
              sx={{ mb: 2 }}
              align="center"
              color="error"
              variant="body2"
            >
              {error || resetPassword.error.message}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Auth>
  );
}
